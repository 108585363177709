<template>
  <v-row class="confirmation__pep">
    <v-col class="confirmation__pep--wrapper px-0" cols="12">
      <cw-checkbox
        id="politicallyExposedPerson"
        v-model="form.politicallyExposedPerson"
      >
        <template #label>
          <span class="mr-2" data-test>
            <translate>
              Politically influential person?
            </translate>
          </span>
          <a
            id="confirmation__pep__more-info"
            @click.prevent.stop="
              $bus.$emit('OPEN_CONFIRMATION_DIALOG', { component: 'DialogPEP' });
              $eventLogger.clickEvent($event);
            "
          >
            <translate>
              More information
            </translate>
          </a>
        </template>
      </cw-checkbox>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CwConfirmationPEP',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
